import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import HeightTransition from "Components/HeightTransition"

import "./CategoriesSelector.style.scss"

const CategoriesSelector = ({
  categoriesMap,
  activePageCategory,
  defaultButtonTitle = "",
  t,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const updateActiveCategory = () => {
      const change = activePageCategory?.change
      const category = activePageCategory?.name
      const id = activePageCategory?.id

      if (!category || id === null) {
        setIsLoaded(true)
        return
      }

      const categoryIndex = categoriesMap.findIndex(
        ({ category_name }) =>
          category_name.toLowerCase() === category.toLowerCase(),
      )

      if (categoryIndex !== -1) {
        const { category_name: name } = categoriesMap[categoryIndex]
        change({ id: categoriesMap[categoryIndex].id, name })
        setIsLoaded(true)
      }
    }

    updateActiveCategory()
  }, [activePageCategory, categoriesMap])

  const renderSingleCategory = ({ category_name, id = null }) => {
    const change = activePageCategory?.change
    const activeId = activePageCategory?.id

    const isActive = activeId === id && isLoaded ? "isActive" : ""

    const handleClick = () => {
      if (
        category_name === defaultButtonTitle.trim() ||
        category_name === t("categorySelector.all")
      ) {
        change({ id: null, name: "" }) // Reset for "All" category
      } else {
        change({ id, name: category_name })
      }
    }

    return (
      <button
        key={id}
        className={`NoButtonStyle SingleCategory ${isActive}`}
        onClick={handleClick}
      >
        {category_name}
      </button>
    )
  }

  const renderCategories = () => (
    <div className="Categories">
      {renderSingleCategory({
        category_name:
          defaultButtonTitle.trim().length > 0
            ? defaultButtonTitle
            : t("categorySelector.all"),
      })}
      {categoriesMap.map(renderSingleCategory)}
    </div>
  )

  const renderActiveCategoryTitle = () => {
    const { activePageCategory: { id, name } = {} } = activePageCategory

    const title = id === null ? "" : name

    return (
      <HeightTransition isOpen={id !== null}>
        <h2 className="ActiveCategoryTitle">{title}</h2>
      </HeightTransition>
    )
  }

  return (
    <div className="CategoriesSelector">
      <button className="leftArrow" aria-label="leftArrow">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m16 4-8 8 8 8"></path>
        </svg>
      </button>
      {renderCategories()}
      {renderActiveCategoryTitle()}
      <button className="rightArrow" aria-label="rightArrow">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m8 4 8 8-8 8"></path>
        </svg>
      </button>
    </div>
  )
}

export default withTranslation()(CategoriesSelector)
