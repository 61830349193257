import React, { useState, useEffect, useRef, useCallback } from "react"
import { useSharedContext } from "Src/contexts/SharedContext"
import ReactSlider from "react-slider"
import BlockTransition from "Components/BlockTransition"
import ProgressiveImage from "Components/ProgressiveImg"
import "./BeforeAndAfterPictureSlider.style.scss"

const MAX_SLIDER_VALUE = 1000
const MARGIN_PX_VALUE = 10

const BeforeAndAfterPictureSlider = ({
  sliderMap,
  otherElementObject,
  elemsTransition = [],
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [movedRight, setMovedRight] = useState(false)
  const [movedLeft, setMovedLeft] = useState(false)

  const beforeLabelRef = useRef(null)
  const afterLabelRef = useRef(null)
  const sliderRef = useRef(null)
  const sliderTopImage = useRef(null)
  const previousIsMobile = useRef(null)

  const { isMobile } = useSharedContext()

  const calculateClipRect = useCallback(
    value => {
      const sliderPaddingTotal = "30px"
      const sliderWidth = isMobile ? "100vw" : "55vw"
      const sliderWidthMax = "750px"
      const sideBarWidth = isMobile ? "0px" : "120px"
      const sideBarMarginLeft = isMobile ? "0px" : "10px"
      // Whole block width
      const finalSliderWidth = isMobile
        ? sliderWidth
        : `min(${sliderWidth}, ${sliderWidthMax})`

      // All stylings which we will remove to get only slider width as its dynamic
      const minusValues = `${sideBarWidth} + ${sideBarMarginLeft} + ${sliderPaddingTotal})`

      // FinalSliderWidth - minusValues represents sliderWidth, and cant be used inside const as it is using min method.
      // Then that whole slider is divided with MAX_SLIDER_VALUE which gives the same proportion value of slider values.
      // And then multiplied by slider returned value.
      return `rect(0px, calc(((${finalSliderWidth} - (${minusValues}) / (${MAX_SLIDER_VALUE}) * ${value})), 1000px, 0px)`
    },
    [isMobile],
  )

  const handleSliderChange = useCallback(
    value => {
      const sliderWidth = sliderRef.current.state.sliderLength

      const getCoefficient = num =>
        Math.round(
          ((num + MARGIN_PX_VALUE) / sliderWidth + Number.EPSILON) * 100,
        ) / 100

      if (sliderWidth) {
        const beforeLabelHideAfter =
          MAX_SLIDER_VALUE * getCoefficient(beforeLabelRef.current.offsetWidth)
        const afterLabelHideAfter =
          MAX_SLIDER_VALUE -
          MAX_SLIDER_VALUE * getCoefficient(afterLabelRef.current.offsetWidth)

        const hideAfter = value > afterLabelHideAfter
        const hideBefore = value < beforeLabelHideAfter

        // if (hideAfter !== movedRight || hideBefore !== movedLeft) {
        //   setMovedRight(hideAfter)
        //   setMovedLeft(hideBefore)
        // }
      }

      sliderTopImage.current.style.clip = calculateClipRect(value)
    },
    [movedLeft, movedRight, calculateClipRect],
  )

  useEffect(() => {
    if (!sliderTopImage.current) return

    // const beforeLabelWidth = beforeLabelRef.current.offsetWidth
    // const afterLabelWidth = afterLabelRef.current.offsetWidth
    handleSliderChange(MAX_SLIDER_VALUE / 2)
  }, [handleSliderChange])

  useEffect(() => {
    if (!sliderMap.length || !sliderRef.current) return

    const setClipStyle = value => {
      handleSliderChange(value)
      previousIsMobile.current = isMobile
    }

    if (!previousIsMobile.current && isMobile) {
      setClipStyle(MAX_SLIDER_VALUE / 2)
    }

    if (isMobile !== previousIsMobile.current) {
      const currentSliderValue = sliderRef.current.state.value[0]
      setClipStyle(currentSliderValue)
    }
  }, [sliderMap, isMobile, handleSliderChange])

  const renderSideBarSlideButton = useCallback(
    ({ title: { value } }, index) => {
      return (
        <button
          className={`SideBarSlideButton ${
            activeSlide === index ? "isActive" : ""
          }`}
          onClick={() => setActiveSlide(index)}
          key={index}
        >
          <span className="SideBarSlideButtonTitle">{value}</span>
          <span className="SideBarSlideButtonIndex">{index + 1}</span>
        </button>
      )
    },
    [activeSlide],
  )

  const renderThumb = useCallback(props => {
    return (
      <span {...props} className="SliderThumbWrapper">
        <span className="SliderThumb" />
      </span>
    )
  }, [])

  const renderSideBar = () => {
    return (
      <div className="SideBar">
        {sliderMap.map((child, index) =>
          renderSideBarSlideButton(child, index),
        )}
      </div>
    )
  }

  const renderSlideImages = useCallback(() => {
    if (!sliderMap.length) return null
    const currentSliderMap = sliderMap[activeSlide]

    const bottomImageSrc = currentSliderMap?.image.attribs.src
    const bottomImageLargeSrc = currentSliderMap?.image.attribs.largeSrc
    const topImageSrc = currentSliderMap?.image2.attribs.src
    const topImageLargeSrc = currentSliderMap?.image2.attribs.largeSrc

    const bottomImageAlt = "Before Image"
    const topImageAlt = "After Image"

    return (
      <>
        <ReactSlider
          className="Slider"
          ref={sliderRef}
          max={MAX_SLIDER_VALUE}
          defaultValue={MAX_SLIDER_VALUE / 2}
          onChange={value => handleSliderChange(value)}
          renderThumb={props => renderThumb(props)}
        />
        <ProgressiveImage
          className="SlideImage"
          largeSrc={bottomImageLargeSrc}
          src={bottomImageSrc}
          alt={bottomImageAlt}
        />
        <ProgressiveImage
          className="SlideImage"
          largeSrc={topImageLargeSrc}
          src={topImageSrc}
          alt={topImageAlt}
          setRef={sliderTopImage}
        />
      </>
    )
  }, [activeSlide, handleSliderChange, renderThumb, sliderMap])

  const renderSlider = useCallback(() => {
    const { BeforeLabel: beforeLabel, AfterLabel: afterLabel } =
      otherElementObject
    return (
      <div className="SliderWrapper">
        {renderSlideImages()}
        {beforeLabel && (
          <span
            className={`ImageLabel ${movedLeft ? "hide" : ""}`}
            ref={beforeLabelRef}
          >
            {beforeLabel.value}
          </span>
        )}
        {afterLabel && (
          <span
            className={`ImageLabel After ${movedRight ? "hide" : ""}`}
            ref={afterLabelRef}
          >
            {afterLabel.value}
          </span>
        )}
      </div>
    )
  }, [
    afterLabelRef,
    beforeLabelRef,
    movedLeft,
    movedRight,
    otherElementObject,
    renderSlideImages,
  ])

  return (
    <BlockTransition elemsTransition={elemsTransition}>
      <div className="SliderBlock">
        {renderSlider()}
        {sliderMap.length && renderSideBar()}
        {otherElementObject.SliderBottomComment && (
          <div className="BottomComment">
            <span className="BottomCommentText">
              {otherElementObject.SliderBottomComment.value}
            </span>
            <span className="BottomCommentArrow" />
          </div>
        )}
      </div>
    </BlockTransition>
  )
}

export default BeforeAndAfterPictureSlider
