import React, {useEffect } from "react"
import { withTranslation } from "react-i18next"
import { renderSidebarWidgets } from "Utils/Sidebars"
import { useSharedContext } from "Src/contexts/SharedContext"

import { isBrowser } from "Utils/Helpers"
import "./Footer.style.scss"
import MenuItem from "Components/Footer/MenuItem.component"
import LocaleSwitcher from "Components/LocaleSwitcher"
import BackToTop from "Components/BackToTop"
import FooterRegion from "Components/FooterRegion"
import Link from "Components/Link"
import { getLanguage } from "Utils/Language"
import { getMenuLink } from "Utils/Link"
import { useFooter } from "Src/hooks/useFooter"
import { useLocation } from "@reach/router"
import BookConsultation from "Components/BookConsultation"

const Footer = () => {
  const { data } = useFooter()

  const { hasPageFooter, setIsConsultaionModalOpen  } = useSharedContext()

  const {
    logo,
    contact,
    contactCommercial,
    description,
    social,
    copyright,
    footerCommercialMenu,
    footerMenu,
    copyrightMenu,
    footerMenuAdditionalColumn,
    bookConsultationForm
  } = data

  const { code: languageCode, language } = getLanguage();
  useEffect(() => {
    const spanElement = document.getElementById('footer_book_link_popup');
    if (spanElement) {
      const handleBookLinkClick = ()=>{
        setIsConsultaionModalOpen(true);
          } 
      spanElement.addEventListener('click', handleBookLinkClick);
      return () => {
        spanElement.removeEventListener('click', handleBookLinkClick);
      };
    }
  }, [languageCode]);

  const renderMenu = () => {
    if (!footerMenu) return null

    return footerMenu.nodes.map(({ slug, items }) => {
      if (!slug.endsWith(language)) return null

      return items.map((item, index) => (
        <MenuItem
          item={item}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.object_id}-${index}`}
        />
      ))
    })
  }

  const renderCommercialMenu = () => {
    if (!footerCommercialMenu) return null

    return footerCommercialMenu.nodes.map(({ slug, items }) => {
      if (!slug.endsWith(language)) return null

      return items.map((item, index) => (
        <MenuItem
          item={item}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.object_id}-${index}`}
        />
      ))
    })
  }

  const TransparencyCode = () => {
    if (languageCode === "us_en") {
      return (
        <Link
          className="CopyrightMenuItem"
          to="https://d7k0rj9u1dp84.cloudfront.net/TCR_TOC_Output/ASO/2024-09-03_bath-fitter-tennessee-inc_index.json"
        >
          Transparency in Coverage
        </Link>
      )
    }
  }

  const renderCopyrightMenu = () => {
    if (!copyrightMenu) return null

    return copyrightMenu.nodes.map(({ slug, wordpress_id, items }, index) => {
      if (!slug.endsWith(language)) return null

      const key = `${wordpress_id}-${index}`

      return (
        <div className="CopyrightMenu" key={key}>
          {items.map(
            ({ object_id, object_slug, title, type, url: menuUrl }) => {
              const key = `CopyrightMenu-${object_id}`
              const { url, isExternal } = getMenuLink({
                type,
                object_slug,
                url: menuUrl,
              })

              return (
                <Link
                  key={key}
                  className="CopyrightMenuItem"
                  to={url}
                  isExternal={isExternal}
                >
                  {title}
                </Link>
              )
            },
          )}

          <noscript>
            <iframe
              title="GTMiframe"
              src="https://www.googletagmanager.com/ns.html?id=GTM-K7GZGHH"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>

          {TransparencyCode()}

          <button
            style={{
              color: "var(--color-white)",
              height: "fit-content",
              backgroundColor: "transparent",
              border: "0",
              minHeight: "fit-content",
              minWidth: "fit-content",
              textTransform: "unset",
              padding: "0",
              fontSize: "1.4rem",
              fontWeight: "100",
              marginTop: "3px",
              marginLeft: "18px",
            }}
            id="ot-sdk-btn"
            className="ot-sdk-show-settings CopyrightMenuItem"
          >
            Cookie Settings
          </button>
        </div>
      )
    })
  }

  const { pathname } = useLocation()

  const isCSPage =
    pathname.includes("commercial-sales") ||
    pathname.includes("ventes-commerciales")

  const locationSwitcherPath =
    pathname.split("/")[2] !== "chatmeter-template" &&
    pathname.split("/")[2] !== "location"

  if (!hasPageFooter) return null
  if (!isBrowser) return null

  return (
    <footer className="Footer">
      <div className="ContentWrapper">
        <div className="Footer-Upper-Section">
          <div className="Logo-Row-Section">
            <div className="Footer-Logo">
              {renderSidebarWidgets(logo, languageCode, undefined, {
                disableImagePreview: true,
              })}
            </div>
            <BackToTop />

            {locationSwitcherPath && (
              <LocaleSwitcher>
                <div className="LocaleSwitcher">
                  <i className="fa fa-globe fa-lg" />
                  <FooterRegion />
                </div>
              </LocaleSwitcher>
            )}
          </div>

          <div className="Details-Row">
            <div className="Company-Details">
              <div className="Company-Details-Upper-Section">
                <div className="Contact-Info">
                  {renderSidebarWidgets(
                    isCSPage ? contactCommercial : contact,
                    languageCode,
                  )}
                  
                </div>

                <div className="Company-Description">
                  {renderSidebarWidgets(description, languageCode)}
                </div>

                <div className="Social-Row-Desktop">
                  <div className="Social-Row">
                    {renderSidebarWidgets(social, languageCode)}
                  </div>
                </div>
              </div>
            </div>

            <div className="Adapted-Sitemap">
              {isCSPage ? renderCommercialMenu() : renderMenu()}
              {renderSidebarWidgets(
                footerMenuAdditionalColumn,
                languageCode,
                "FooterMenuAdditionalColumn",
              )}
              {renderCopyrightMenu()}
            </div>
          </div>

          <div className="Social-Row-Mobile">
            <div className="Social-Row">
              {renderSidebarWidgets(social, languageCode)}
            </div>
          </div>
        </div>

        <div className="Footer-Row">
          <div className="Footer-Copyright">
            {renderSidebarWidgets(copyright, languageCode)}
          </div>
          {renderCopyrightMenu()}
        </div>
      </div>
    </footer>
  )
}

export default withTranslation()(Footer)
